import React, {Fragment} from "react";
import { ReactComponent as AccessibilityIcon } from "../icons/accessibility.svg";
import { ReactComponent as WebdesignIcon } from "../icons/webdesign.svg";
import { ReactComponent as AgileIcon } from "../icons/agile.svg";
import { ReactComponent as SharingIcon } from "../icons/sharing.svg";
import Fade from "react-reveal/Fade";

const AboutIcons = {
    accessibility: AccessibilityIcon,
    webdesign: WebdesignIcon,
    agile: AgileIcon,
    sharing: SharingIcon
};

const About = ({data}) => {
    return (
        <Fragment>
            <h2 className="u-a11y-hidden">About me</h2>
            <div className="grid grid--zigzag">
                {
                    data.map((usp, key) => {
                        const AboutIcon = AboutIcons[usp.icon];

                        return (
                            <Fragment key={key}>
                                <div className="grid__item feature">
                                    <Fade bottom distance={'2rem'} delay={0} fraction={0.4}>
                                        <h2 className="feature__heading heading heading--s">{usp.eyebrow}</h2>
                                    </Fade>
                                    <Fade bottom distance={'2rem'} delay={200} fraction={0.4}>
                                        <h3 className="heading heading--l feature__title">{usp.title}</h3>
                                    </Fade>
                                    <Fade bottom distance={'2rem'} delay={400} fraction={0.4}>
                                        <Fragment>
                                            <p className="feature__description">{usp.description}</p>
                                            <hr className="divider divider--secondary"/>
                                        </Fragment>
                                    </Fade>
                                </div>
                                <div className="grid__item">
                                    <Fade bottom distance={'2rem'} delay={300} fraction={0.4}>
                                        <div className="media">
                                            <AboutIcon className="media__icon" fill="white" alt={usp.title} width="100%" />
                                        </div>
                                    </Fade>
                                </div>
                            </Fragment>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}


export default About
