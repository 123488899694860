/**
 * Import all files from this directory and export the array
 */

const req = require.context('.', true, /^(.*\.(json$))[^.]*$/i);
const files = [];

req.keys().forEach(key => {
    files.push(req(key));
});

export default files.reverse();

