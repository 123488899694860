import React, {Component} from "react";
import Fade from "react-reveal/Fade";
import classNames from "classnames";

class Hero extends Component {

    render() {
        const hasEyebrow = (this.props.eyebrow !==  null);
        const hasTitle = (this.props.title !==  null);
        const hasDescription = (this.props.description !==  null);

        let HeroEyeBrowTag = 'p';
        let HeroTitleTag = 'h1';

        if (this.props.flipTitleLevels === true) {
            HeroEyeBrowTag = 'h1';
            HeroTitleTag = 'p';
        }

        let heroClassName = classNames({
            'hero': true,
            'hero--light': this.props.lightMode,
            'hero--error': this.props.error
        });

        return (
            <header className={heroClassName}>
                <div className="hero__background" style={this.props.rootStyle}/>
                <div className="container container--large hero__container" style={{color: this.props.titleColor}}>
                    {
                        hasEyebrow &&
                        <Fade top distance={'1rem'} delay={400}>
                            <HeroEyeBrowTag
                                className="hero__eyebrow"
                                dangerouslySetInnerHTML={{__html: this.props.eyebrow}}
                            />
                        </Fade>
                    }

                    {
                        hasTitle &&
                        <Fade top distance={'1rem'} delay={600}>
                            <HeroTitleTag
                                className="hero__title heading heading--m"
                                dangerouslySetInnerHTML={{__html: this.props.title}}
                            />
                        </Fade>
                    }

                    {
                        hasDescription &&
                        <Fade top distance={'1rem'} delay={800}>
                            <p className="hero__intro"
                               dangerouslySetInnerHTML={{__html: this.props.description}}/>
                        </Fade>
                    }

                    {this.props.children}
                </div>
            </header>
        )
    }
}


export default Hero
