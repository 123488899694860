import React, {Fragment} from 'react';
import { Router, Location, globalHistory } from '@reach/router';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Home from "./pages/Home";
import Work from "./pages/Work";
import Content from "./pages/Content";
import Topbar from "./components/Topbar";
import NotFound from "./pages/NotFound";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.historyUnsubscribe = null;

        this.state = {
            screenWidth: null
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.historyUnsubscribe = globalHistory.listen(({location}) => {
            window.gtag('config', 'UA-1272052-1', {
                'page_path': location.pathname
            });
        });

        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        this.historyUnsubscribe();
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    render() {
        return (
            <Fragment>
                <Topbar/>
                <FadeTransitionRouter>
                    <Home path="/" state={this.state} />
                    <Work path="work/:slug" state={this.state} />
                    <Content path="page/:slug" state={this.state} />
                    <NotFound default />
                </FadeTransitionRouter>
            </Fragment>
        );
    }
}

const FadeTransitionRouter = props => (
    <Location>
        {({ location }) => (
            <TransitionGroup className="content">
                <CSSTransition key={location.key} classNames="slide" timeout={{
                    appear: 0,
                    enter: 800,
                    exit: 800,
                }}>
                    <Router primary={true} location={location} className="router">
                        {props.children}
                    </Router>
                </CSSTransition>
            </TransitionGroup>
        )}
    </Location>
);

export default App;
