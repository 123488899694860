import React, {Component} from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import WorkData from "../_work/index";
import NotFound from "./NotFound";
import Fade from "react-reveal/Fade";
import ReactTooltip from 'react-tooltip';

class Work extends Component {

    render() {
        let project = WorkData.find(project => project.slug === this.props.slug);

        if (!project) {
            return (<NotFound/>)
        }

        const toolsTitleMap = {
            'angular': 'Angular',
            'backbone': 'Backbone',
            'craft': 'Craft CMS',
            'css': 'CSS',
            'git': 'Git',
            'google-analytics': 'Google Analytics',
            'gulp': 'Gulp',
            'html': 'HTML5',
            'jquery': 'jQuery',
            'netlify': 'Netlify',
            'nodejs': 'NodeJS',
            'npm': 'npm',
            'react': 'React',
            'redux': 'Redux',
            'rollupjs': 'RollupJS',
            'saucelabs': 'Saucelabs',
            'sendgrid': 'Sendgrid',
            'typescript': 'Typescript',
            'vue': 'Vue',
            'webpack': 'Webpack',
        };

        return (
            <article className="page">
                <Hero
                    eyebrow={project.title}
                    title={project.maintask}
                    description={project.description}
                    lightMode={project.light}
                    rootStyle={{
                        background: 'linear-gradient(153deg, ' + project.style.primary + ' 0%, ' + project.style.secondary + ' 95%)'
                    }}
                    titleColor={project.style.text}
                    flipTitleLevels={true}
                >
                    <ul className="list grid grid--quatro-trio h-mb-1">
                        <li className="grid__item">
                            <dl className="specs">
                                <dt className="specs__title">Client</dt>
                                <dd className="specs__text">{project.client}</dd>
                            </dl>
                        </li>
                        <li className="grid__item">
                            <dl className="specs">
                                <dt className="specs__title">Period</dt>
                                <dd className="specs__text">{project.period}</dd>
                            </dl>
                        </li>
                        <li className="grid__item">
                            <dl className="specs">
                                <dt className="specs__title">Role</dt>
                                <dd className="specs__text">{project.role}</dd>
                            </dl>
                        </li>
                    </ul>
                    <div className="scroll-indicator"></div>
                </Hero>

                <section className="section section--dark">
                    <div className="container">
                        <h2 className="h-a11y-hidden">Impression</h2>
                        <div className="well">
                            <div className="grid grid--playfull">
                                {
                                    project.images.map((item, key) => {
                                        return(
                                            <div key={key} className="grid__item">
                                                <Fade bottom distance={'2rem'} fraction={0.4}>
                                                    <figure className="media media--auto-height media--white media--shaded media--rounded">
                                                        <img src={item.path} alt={item.title} className="media__image" />
                                                    </figure>
                                                </Fade>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container container--large">
                        <h2 className="heading heading--s h-text-center h-text-uppercase h-mb-1">Some of the used tools on this project</h2>
                        <ul className="logos logos--big logos--grayscale">
                            {
                                project.tools.map((item, key) => {
                                    return (
                                        <li className="logos__item" key={key}>
                                            <img src={`/images/svg/logo/${item}.svg`} alt={item} className="logos__item-logo" data-tip data-for={item} />
                                            <ReactTooltip className="tooltip" id={item} effect="solid">{toolsTitleMap[item]}</ReactTooltip>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </section>
                <Footer/>
            </article>
        )
    }
}

export default Work
