import React from "react";
import { Link } from '@reach/router';
import Fade from "react-reveal/Fade";

const Project = ({ screenWidth, index, project }) => {

    const fadeDelay = (screenWidth >= 600) ? ((index * 200) + 800) : 0;

    return (
            <article className="project">
                <Fade bottom distance={'2rem'} delay={ fadeDelay }>
                    <Link to={ '/work/' + project.slug } className="project__link">
                        <h3 className="project__cltle u-a11y-hidden">{project.title}</h3>
                        <div className="project__background" style={{ background: 'linear-gradient(153deg, ' + project.style.primary + ' 0%, ' + project.style.secondary + ' 95%)'}}></div>
                        <figure className="project__logo">
                            <img src={ `/images/svg/${project.logo}` } alt={project.title} className="project__logo-image"/>
                        </figure>
                        <div className="project__content">
                            <dl className="specs">
                                <dt className="specs__title">Client</dt>
                                <dd className="specs__text">{project.client}</dd>

                                <dt className="specs__title">Period</dt>
                                <dd className="specs__text">{project.period}</dd>

                                <dt className="specs__title">Technologies</dt>
                                <dd className="specs__text">{project.technologies}</dd>
                            </dl>
                        </div>
                    </Link>
                </Fade>
            </article>
    )
}

export default Project
