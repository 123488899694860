import React, {useEffect, useState} from "react";
import { Link, globalHistory } from '@reach/router';
import Fade from "react-reveal/Fade";

function Topbar () {

    const initialState = {
        isRoot: globalHistory.location.pathname === '/'
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        const removeListener = globalHistory.listen(params => {
            const { location } = params;

            const newState = Object.assign({}, initialState, {
                isRoot: location.pathname === '/'
            });

            setState(newState);
        });
        return () => {
            removeListener();
        };
    }, [initialState]);

    return (
        <div className="topbar">
            <div className="container">
                <Link to="/" className="topbar__root">
                    <figure className="topbar__root-figure">
                        <img src="/images/ctigelaar.jpg" alt="me" className="topbar__root-image" />
                    </figure>
                    <h1 className="u-a11y-hidden">Christiaan Tigelaar</h1>
                    <Fade left distance={'1rem'} delay={500} when={!state.isRoot} collapse duration={200}>
                        <div>
                            <span className="topbar__root-name">&lsaquo; Back</span>
                        </div>
                    </Fade>
                </Link>
            </div>
        </div>
    )
}

export default Topbar
