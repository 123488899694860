import React, {Component} from "react";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import PagesData from "../_pages/index";
import NotFound from "./NotFound";

class Content extends Component {

    render() {
        let page = PagesData.find(page => page.slug === this.props.slug);

        if (!page) {
            return (<NotFound/>)
        }

        return (
            <article className="page">
                <Hero
                    eyebrow={ page.title }
                    title={ null }
                    description={ null }
                    flipTitleLevels={true}
                />
                <section className="section">
                    <div className="container container--large" dangerouslySetInnerHTML={{ __html: page.content}}></div>
                </section>
                <Footer/>
            </article>
        )
    }
}

export default Content
