import React, {Component, Fragment} from "react";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import WorkData from "../_work/index";
import dataHomePage from "../data/homepage.json";
import About from "../components/About";
import Project from "../components/Project";
import Fade from "react-reveal/Fade";

class Home extends Component {

    render() {
        return (
            <article className="page">
                <Hero
                    eyebrow={ dataHomePage.hero.eyebrow }
                    title={ dataHomePage.hero.title }
                    description={ dataHomePage.hero.description }
                />
                <section className="container container--sm-small">
                    <div className="grid grid--quatro">
                        <h2 className="u-a11y-hidden">My latest work</h2>
                        {
                            WorkData.map((project, i) => {
                                return (<Project key={i} screenWidth={this.props.state.screenWidth} index={i} project={project} />)
                            })
                        }
                    </div>
                </section>
                <section className="container container--large clients">
                    <h2 className="clients__heading h-text-center">And many more</h2>
                    <ul className="clients__list">
                        {
                            dataHomePage.clients.map((client, key) => {
                                return (
                                    <li className="clients__item" key={key}>
                                        <Fade bottom distance={'25%'} delay={ (key * 200) }>
                                            <Fragment>
                                                <img src={`images/svg/work/${client.logo}`} alt={client.title} className="clients__item-logo" />
                                            </Fragment>
                                        </Fade>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </section>
                <section className="section section--dark">
                    <div className="container container--large">
                        <About data={ dataHomePage.about } />
                    </div>
                </section>
                <Footer/>
            </article>
        )
    }
}

export default Home
