import React, {Component} from "react";
import Hero from "../components/Hero";
import Footer from "../components/Footer";

class NotFound extends Component {

    render() {
        return (
            <article className="page">
                <Hero
                    eyebrow={ 'Error <span class="hero__name">404</span>' }
                    title={ 'The requested page does not exist.' }
                    description={ 'Please try another page or go back to the homepage.' }
                    error={true}
                />
                <Footer/>
            </article>
        )
    }
}

export default NotFound
